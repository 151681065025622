/**
 * Add your config changes here.
 * @module config
 */

import applyItaliaConfig from '@italia/config/italiaConfig';
import '@plone/volto/config';

export default function applyConfig(voltoConfig) {
  let config = applyItaliaConfig(voltoConfig);

  config.settings = {
    ...config.settings,
    matomoSiteId: 'XKqJr8dqYR',
    matomoUrlBase: 'https://ingestion.webanalytics.italia.it/',
    // italiaThemeViewsConfig: {
    //   ...config.settings.italiaThemeViewsConfig,
    //   imagePosition: 'afterHeader', // possible values: afterHeader, documentBody
    // },
    siteProperties: {
      /*
        Per tutte le props di siteProperties, può essere definito un oggetto per i siti multilingua, strutturato in questo modo:
        {'default': _valore_della_prop, 'it': _valore_della_prop,...}
        Se l'oggetto non è strutturato in questo modo, viene usato l'oggetto stesso, altrimenti viene preso il valore della corrispondente alla prop della lingua corrente o alla prop 'default' se esiste.
      */
      ...config.settings.siteProperties,
      siteTitle: 'Unioncamere Emilia Romagna',
      siteSubtitle: '',
      parentSiteTitle: null,
      parentSiteURL: null,
      subsiteParentSiteTitle: 'Unioncamere Emilia Romagna',
      footerNavigationDepth: 1,
      // enableCustomerSatisfaction: false, // false per disabilitare
      //arLoginUrl: '/it/area-riservata',
      //arLogoutUrl: '/logout',
      // smallFooterLinks: {
      //   default: [{ title: 'Mappa del sito', url: '/sitemap' }],
      //   it: [{ title: 'Mappa del sito', url: '/it/sitemap' }],
      //   en: [{ title: 'Sitemap', url: '/en/sitemap' }],
      // },

      headerslimTertiaryMenu: {
        it: [
          { title: 'RSS', url: '/rss-feed' },
          //{ title: 'Novità', url: '/it/novita' },
        ],
      },
      enableCustomerSatisfaction: true,
      enableVoltoFormBlockCaptcha: false,
    },

    'volto-gdpr-privacy': {
      ...config.settings['volto-gdpr-privacy'],
      defaultPanelConfig: {
        ...config.settings['volto-gdpr-privacy'].defaultPanelConfig,
        last_updated: '2025-01-07T16:30:00+00:00',
        text: {
          it: {
            title: 'Usiamo i cookie',
            description:
              "Questo sito utilizza i cookie tecnici di navigazione e di sessione per garantire un miglior servizio di navigazione del sito, e cookie analitici per raccogliere informazioni sull'uso del sito da parte degli utenti.  Utilizza anche cookie di profilazione dell'utente. Per i cookie di profilazione puoi decidere se abilitarli o meno cliccando sul pulsante 'Impostazioni'. Per saperne di più, su come disabilitare i cookie oppure abilitarne solo alcuni, consulta la nostra <a href='/privacy-policy'>Cookie Policy</a>.",
          },
          en: {
            title: 'We use cookies',
            description:
              "This site uses technical navigation and session cookies to ensure a better site navigation service, and analytical cookies to collect information on the use of the site by users. It also uses user profiling cookies. For profiling cookies you can decide whether to enable them or not by clicking on the 'Settings' button. To find out more, on how to disable cookies or enable only some of them, consult our <a href='/en/privacy-policy'>Cookie Policy</a>.",
          },
        },
        profiling: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.profiling,
          choices: [
            {
              config_key: 'YOUTUBE',
              referenceUrls: [
                'youtube.com',
                'youtube-nocookie.com',
                'youtu.be',
              ],
              text: {
                it: {
                  title: 'Youtube',
                  description:
                    "I cookie di profilazione di Youtube permettono di mostrarti le pubblicità che potrebbero interessarti di più, fare analisi di accesso alla pagina e sul comportamento dell'utente, facilitare l'accesso ai servizi di Google.",
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'Per vedere il video, accetta i cookies di Youtube.',
                },
                en: {
                  title: 'Youtube',
                  description:
                    'Youtube profiling cookies allow you to show advertisements that may interest you the most, analyze page access and user behavior, facilitate access to Google services. ',
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'To view the video, please accept Youtube cookies.',
                },
                es: {
                  title: 'Youtube',
                  description:
                    'Las cookies de perfil de Youtube le permiten mostrar los anuncios que más le pueden interesar, analizar el acceso a la página y el comportamiento del usuario, facilitar el acceso a los servicios de Google.',
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'Para ver el video, acepte las cookies de Youtube.',
                },
                fr: {
                  title: 'Youtube',
                  description:
                    "Les cookies de profilage Youtube vous permettent d'afficher les publicités susceptibles de vous intéresser le plus, d'analyser l'accès aux pages et le comportement des utilisateurs, de faciliter l'accès aux services Google.",
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'Pour voir la vidéo, veuillez accepter les cookies Youtube.',
                },
              },
            },
            {
              config_key: 'VIMEO',
              referenceUrls: ['vimeo.com'],
              text: {
                it: {
                  title: 'Vimeo',
                  description:
                    "I cookie di profilazione di Vimeo permettono di fare analisi di accesso alla pagina e sul comportamento dell'utente, e di mostrarti le pubblicità che potrebbero interessarti di più.",
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'Per vedere il video, accetta i cookies di Vimeo.',
                },
                en: {
                  title: 'Vimeo',
                  description:
                    'Vimeo profiling cookies allow you to analyze page access and user behavior, and to show you the advertisements that may interest you the most.',
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'To view the video, please accept Vimeo cookies.',
                },
                es: {
                  title: 'Vimeo',
                  description:
                    'Las cookies de creación de perfiles de Vimeo le permiten analizar el acceso a la página y el comportamiento del usuario, y mostrarle los anuncios que más le pueden interesar.',
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'Para ver el video, acepte las cookies de Vimeo.',
                },
                fr: {
                  title: 'Vimeo',
                  description:
                    "Les cookies de profilage Vimeo vous permettent d'analyser l'accès aux pages et le comportement des utilisateurs, et de vous montrer les publicités qui pourraient vous intéresser le plus.",
                  //text to show in conditional embed if that cookies are not enabled
                  conditional_embed_text:
                    'Pour voir la vidéo, veuillez accepter les cookies Vimeo.',
                },
              },
            },
          ],
        },
        technical: {
          ...config.settings['volto-gdpr-privacy'].defaultPanelConfig.technical,
          choices: [
            {
              config_key: 'MATOMO',
              text: {
                it: {
                  title: 'Matomo',
                  description:
                    "I cookie di Matomo sono usati per analizzare la navigazione sul sito al fine di migliorarla e fornire all'utente un'esperienza di navigazione migliore possibile.",
                },
                en: {
                  title: 'Matomo',
                  description:
                    'Matomo cookie are used to analyze navigation on the site in order to improve it and provide the user with the best possible browsing experience.',
                },
                fr: {
                  title: 'Matomo',
                  description:
                    "Les cookie de Matomo sont utilisés pour analyser la navigation sur le site afin de l'améliorer et offrir à l'utilisateur la meilleure expérience de navigation possible.",
                },
              },
            },
            /*...config.settings[
              'volto-gdpr-privacy'
            ].defaultPanelConfig.technical.choices.filter(
              (f) =>
                f.config_key !== 'GANALYTICS' && f.config_key !== 'GRECAPTCHA',
            ),*/
          ],
        },
      },
    },
  };

  // config.views = {
  //   ...config.views,
  // };
  // config.widgets = {
  //   ...config.widgets,
  // };

  config.blocks.blocksConfig.listing = {
    ...config.blocks.blocksConfig.listing,
    variations: [
      ...config.blocks.blocksConfig.listing.variations,
      // {
      //   id: 'cardWithSideImageTemplate',
      //   isDefault: false,
      //   title: 'Card con immagine affiancata',
      //   template: CardWithSideImageTemplate,
      //   skeleton: CardWithSideImageTemplateSkeleton,
      //   schemaEnhancer: ({ schema, formData, intl }) => {
      //     let pos = addDefaultOptions(schema, formData, intl);
      //     addCardWithSideImageTemplateOptions(schema, formData, intl, pos);
      //     return schema;
      //   },
      // },
    ],
    listing_bg_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
    listing_items_colors: [], //{name:'blue', label:'Blu'},{name:'light-blue', label:'Light blue'},{name:'sidebar-background', label:'Grey'}
  };

  return config;
}
